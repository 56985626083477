.main-content {
  height: 100vh;
  overflow: auto;
}

.left-section {
  padding-bottom: 160px;
}

.back-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #0C2D48;
}

.back-button {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.2s;
  color: white;
  background-color: #2E8BC0;
  width: 100%;
  max-width: 400px;
}

.back-button:hover {
  opacity: 0.8;
}
