/* SimulationResultsView.css */
.simulation-results {
  padding: 5px;
  margin: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.2s;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.simulation-results:hover {
  transform: translateY(-5px);
}

.percentage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
  position: relative;
  height: 100%;
}

.percentage-container p {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  position: relative;
  z-index: 1;
  color: #B1D4E0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.percentage-background {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #10A710;
  z-index: 0;
  transition: height 2.5s ease-in-out;
}

@keyframes popAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.simulation-results:hover .percentage-background {
  animation: popAnimation 2.5s ease-in-out;
}