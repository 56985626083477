.board-cards-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .board-cards {
    display: inline-block;
    padding: 4px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .board-cards:hover {
    transform: translateY(-5px);
  }
  
  .board-cards-inner {
    display: flex;
    gap: 5px;
  }