/* Card.css */
/* ... */

.card {
  width: 40px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card.input-view {
  flex: 1 0 auto;
  max-width: 50px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card.input-view.wrapped {
  max-width: 23px;
  height: 45px;
}

.card:hover {
  transform: translateY(-5px);
}

.card-front {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.suit {
  font-size: 24px;
}

.suit.red {
  color: #dc143c;
}

.suit.blue {
  color: #4169e1;
}

.suit.green {
  color: #2e8b57;
}

.suit.black {
  color: #2f4f4f;
}

.card.red {
  background-color: #fff1f2;
}

.card.blue {
  background-color: #f0f8ff;
}

.card.green {
  background-color: #e6f5e6;
}

.card.black {
  background-color: #f0f0f0;
}

.rank {
  margin-top: 5px;
}

.card-back {
  background: linear-gradient(45deg, #0C2D48, #145DA0, #2E8BC0, #B1D4E0);
  background-size: 400% 400%;
  animation: gradientAnimation 10s ease infinite;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}