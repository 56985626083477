.players-cards-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.players-cards-stack {
  display: flex;
  flex-wrap: flex;
  gap: 10px;
  justify-content: center;
}

.player-hand {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.player-hand:hover {
  transform: translateY(-5px);
}

.player-container {
  display: flex;
  align-items: center;
}

.card-container {
  display: flex;
  gap: 5px;
}