/* ShareHandView.css */
.share-hand-view {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 1000;
  }
  
  .share-hand-button {
    background-color: #145DA0;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
  
  .share-hand-button:hover {
    background-color: #8FBDD3;
  }