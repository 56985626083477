/* deck.css */
.deck-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deck-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.suit-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}