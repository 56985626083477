/* MenuView.css */
.menu-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #B1D4E0; /* Set the text color to light */
}

.menu {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 220px;
}

.menu-content {
  width: 100%;
  max-width: 400px;
}

.info-section {
  margin-bottom: 30px;
}

.info-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.info-section h3 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.info-section ol {
  padding-left: 20px;
  margin-bottom: 10px;
}

.info-section button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background-color: #145DA0;
  text-align: center;
  text-decoration: none;
}

.info-section button:hover {
  opacity: 0.8;
}

.settings-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #0C2D48;
}

.go-back-button {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.2s;
  color: white;
  background-color: #2E8BC0;
  width: 100%;
  max-width: 400px;
}

.go-back-button:hover {
  opacity: 0.8;
}