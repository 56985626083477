/* SimulationResultsModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: #B1D4E0;
    padding: 24px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }

.modal-header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}
  
  .modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }
  
  .close-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
    background-color: #2E8BC0;
    color: white;
    width: 100%;
    margin: 0 24px;
  }
  
  .close-button:hover {
    background-color: #1976d2;
  }
  
  .equity-group {
    margin-bottom: 16px;
  }
  
  .equity-item {
    margin-bottom: 12px;
  }
  
  .equity-item p {
    margin: 0;
    font-size: 16px;
  }
  
  .percentage-line {
    height: 8px;
    background-color: #FF7F50;
    margin-top: 4px;
    border-radius: 4px;
  }

  .board-selection {
    display: flex;
    justify-content: center;
  }
  
  .board-selection button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
    background-color: #2E8BC0;
    color: white;
    margin: 0 8px;
  }
  
  .board-selection button:hover {
    background-color: #1976d2;
  }
  
  .board-selection button.active {
    background-color: #145DA0;
  }