.action-buttons-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 380px;
  height: 100px;
  background-color: #0C2D48;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  z-index: 1000;
  margin: 0 auto;
  border-radius: 12px 12px 0 0;
}

.menu-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  background-color: #2E8BC0;
  color: white;
  transition: background-color 0.2s;
}

.clear-all-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  background-color: #FF7F50;
  color: white;
  transition: background-color 0.2s;
}

.run-sims-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  background-color: #145DA0;
  color: white;
  transition: background-color 0.2s;
}

.game-type-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  background-color: #2E8BC0;
  color: white;
  transition: background-color 0.2s;
}

.game-type-button:hover {
  opacity: 0.8;
}

.game-type-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.game-type-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.game-type-modal-content {
  background-color: #0C2D48;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.game-type-modal-button {
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  background-color: #2E8BC0;
  color: white;
}

.game-type-modal-button:hover {
  background-color: #145DA0;
}

.game-type-modal-button.selected {
  background-color: #145DA0;
  color: white;
}

.menu-button:hover,
.clear-all-button:hover,
.run-sims-button:hover {
  opacity: 0.8;
}

.menu-button:disabled,
.clear-all-button:disabled,
.run-sims-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.calculating-animation {
  display: flex;
  align-items: center;
  gap: 10px;
}

.spinner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spin 1s linear infinite;
}

.quantity-button {
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  background-color: #2E8BC0;
  color: white;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantity-button:hover {
  opacity: 0.8;
}

.quantity-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}