/* App.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0C2D48;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.deck-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.suit-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}

.clear-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.clear-button:hover {
  background-color: #cc0000;
}
