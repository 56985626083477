/* GameModifiersView.css */
.game-modifiers {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #0C2D48;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  border-radius: 8px 8px 0 0;
}

.simulation-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #145DA0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 4px;
}

.simulation-inputs {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.number-of-simulations-selector input[type="range"] {
  flex: 1;
}

.game-type-selector {
  width: 100%;
  margin-bottom: 20px;
}

.share-hand {
  margin-bottom: 20px;
}

.share-hand-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  background-color: #FF7F50;
  color: white;
}

.share-hand-button:hover {
  background-color: #145DA0;
}

.enable-two-boards-and-share-hand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.two-boards-selector {
  width: 100%;
  margin-bottom: 20px;
}

.two-boards-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  background-color: #2E8BC0;
  color: white;
}

.two-boards-button:hover {
  background-color: #145DA0;
}

